import HomePage from './pages/HomePage/HomePage';
import './App.scss';

function App() {
  return (
    <div className="App">
      <HomePage />
    </div>
  );
}

export default App;
